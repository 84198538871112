/** @format */

import React from "react";
import logo from "../../assets/E-WEST-AFRICAN-A.svg";
import style from "../style/SideBar.module.css";
import { NavLink, useNavigate, Link } from "react-router-dom";
import dashboard from "../../assets/grid_view_FILL1_wght300_GRAD0_opsz24.svg";
import attendee from "../../assets/person_FILL0_wght300_GRAD0_opsz24.svg";
import verify from "../../assets/verified_user_FILL0_wght300_GRAD0_opsz24.svg";
import logoutImg from "../../assets/logout_FILL0_wght300_GRAD0_opsz24 1.svg";
import { useUserAuth } from "../../context/UserAuthContext";
import { IoClose } from "react-icons/io5";

function SideBar({ setToggle, toggle }) {
  const updateToggle = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  const { logout } = useUserAuth();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await logout();
      navigate("/");
      localStorage.clear();
    } catch (error) {
      alert(error.message);
    }
  }
  return (
    <div className={style.sidebar}>
      <div className="text-right d-block d-lg-none">
        <IoClose
          onClick={() => updateToggle()}
          style={{ cursor: "pointer", fontSize: "28px", color: "#ffffff" }}
        />
      </div>
      <div className="mb-4 navbar-brand row m-0 align-items-center text-white">
        <Link to={"/"}>
          <img src={logo} alt="logo" width="200px" className="mr-2" />
        </Link>
      </div>
      <div className="menu">
        <ul className={style.navbar_nav}>
          <li className={style.nav_item}>
            <NavLink
              to="dashboard"
              className={({ isActive }) =>
                isActive ? style.active : style.link
              }>
              <img
                src={dashboard}
                alt="dashboard"
                className={`mr-3 ${style.icon}`}
              />
              Dashboard
            </NavLink>
          </li>
          <li className={style.nav_item}>
            <NavLink
              to="attendees"
              className={({ isActive }) =>
                isActive ? style.active : style.link
              }>
              <img
                src={attendee}
                alt="attendee"
                className={`mr-2 ${style.icon}`}
              />
              Attendees
            </NavLink>
          </li>
          <li className={style.nav_item}>
            <NavLink
              to="verification"
              className={({ isActive }) =>
                isActive ? style.active : style.link
              }>
              <img src={verify} alt="verify" className={`mr-2 ${style.icon}`} />
              Verifications
            </NavLink>
          </li>
        </ul>
        <div className={style.logout_container}>
          <ul>
            <li className={style.nav_item} onClick={handleLogout}>
              <button className="font-weight-normal px-2 text-white">
                <img
                  src={logoutImg}
                  alt="logoutImg"
                  className={`mr-2 ${style.icon}`}
                />
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
