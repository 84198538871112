/** @format */

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashboardLayout from "../admin/DashboardLayout";
import Dashboard from "../admin/components/Dashboard";
import Attendees from "../admin/components/Attendees";
import Verification from "../admin/components/Verification";
import { UserAuthContextProvider } from "../context/UserAuthContext";
import PrivateRoute from "../components/PrivateRoute";
import { RegisterAndAttendeesContextProvider } from "../context/RegisterAndAttendeesContext";

function AdminRoute() {
  return (
    <UserAuthContextProvider>
      <RegisterAndAttendeesContextProvider>
        <Routes>
          <Route path="" element={<DashboardLayout />}>
            <Route
              path="dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="attendees"
              element={
                <PrivateRoute>
                  <Attendees />
                </PrivateRoute>
              }
            />
            <Route
              path="verification"
              element={
                <PrivateRoute>
                  <Verification />
                </PrivateRoute>
              }
            />
            <Route
              path=""
              element={<Navigate to={"/layout/dashboard"} replace />}
            />
          </Route>
        </Routes>
      </RegisterAndAttendeesContextProvider>
    </UserAuthContextProvider>
  );
}

export default AdminRoute;
