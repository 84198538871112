/** @format */

import React, { useState } from "react";
import UserDetails from "../../components/UserDetails";
import { useRegisterAndAttendeesUsers } from "../../context/RegisterAndAttendeesContext";
function Verification() {
  const { registerAndAttendee } = useRegisterAndAttendeesUsers();
  const [isKeyNumber, setIsKeyNumber] = useState(false);
  const [singleUserDetails, setSingleUserDetails] = useState();
  const [attendeeID, setAttendeeID] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelect = (attendeeID) => {
    if (attendeeID !== undefined && attendeeID !== "") {
      let singleAttendee = registerAndAttendee.filter((attendee) => {
        let userDetails;
        const attendeeUniqueID = attendee.id;
        const attendeesKeyNumber = attendee.userDetails.keyNumber;
        if (attendeeUniqueID === attendeeID.toString()) {
          userDetails = attendeeUniqueID;
        } else if (attendeesKeyNumber === attendeeID.toString()) {
          userDetails = attendeesKeyNumber;
        }
        return userDetails;
      });
      if (singleAttendee.length !== 0) {
        setSingleUserDetails([...singleAttendee]);
        setIsKeyNumber(true);
      } else {
        setIsKeyNumber(false);
        setErrorMessage(
          "Attendee Do not exit. Please ensure that you have the right attend id"
        );
        setTimeout(() => {
          setErrorMessage("");
        }, 4000);
      }
    }
  };

  return (
    <>
      <div className="row m-0">
        <div className="col-12 mb-2">
          <h4>Verification</h4>
        </div>
      </div>
      <div
        className="row m-0 justify-content-center align-items-center"
        style={{
          minHeight: "76vh",
        }}>
        {!isKeyNumber ? (
          <div className="col-12 col-sm-8 col-md-6 col-lg-6 mb-2">
            {!!errorMessage && (
              <div className="alert alert-danger col-12" role="alert">
                {errorMessage}
              </div>
            )}
            <div className="card border-0 shadow">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="Attendee Id">Attendee Id or Key Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="AttendeeID"
                    onChange={(e) => setAttendeeID(e.target.value)}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => handleSelect(attendeeID)}
                  className="app-btn-primary mt-4">
                  Validate member
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            <UserDetails
              singleUserDetails={singleUserDetails}
              onSetIsKeyNumber={setIsKeyNumber}
              onSetAttendeeID={setAttendeeID}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Verification;
