/** @format */

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "../auth/AuthLayout";
import SignIn from "../auth/components/SignIn";
import { UserAuthContextProvider } from "../context/UserAuthContext";
import PublicRoute from "../components/PublicRoute";

const AuthRoute = () => {
  return (
    <UserAuthContextProvider>
      <Routes>
        <Route path="" element={<AuthLayout />}>
          <Route
            path="/auth/sign-in"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route path="" element={<Navigate to={"/auth/sign-in"} replace />} />
        </Route>
      </Routes>
    </UserAuthContextProvider>
  );
};

export default AuthRoute;
