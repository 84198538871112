/** @format */

import React from "react";
import UserDetailsInfo from "./UserDetailsInfo";
import { IoClose } from "react-icons/io5";

function UserDetails({ singleUserDetails, onSetIsKeyNumber, onSetAttendeeID }) {
  const handleParentStateUpdate = () => {
    onSetIsKeyNumber(false);
    onSetAttendeeID("");
  };
  return (
    <div style={{ position: "relative" }}>
      <div
        className="card border-0 shadow px-4 py-3"
        style={{ minHeight: "70vh" }}>
        <div className="row m-0 mb-2">
          <div className="col-12 text-right px-0">
            <IoClose
              onClick={() => handleParentStateUpdate(false)}
              style={{ cursor: "pointer", fontSize: "20px" }}
            />
          </div>
        </div>
        <UserDetailsInfo singleUserDetails={singleUserDetails} />
      </div>
    </div>
  );
}

export default UserDetails;

export const UserDetailsModal = ({ singleUserDetails }) => {
  return (
    <div style={{ position: "relative" }}>
      <div
        className="modal fade"
        id="exampleModal"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="px-4 py-3" style={{ minHeight: "70vh" }}>
                <div className="row m-0 mb-2">
                  <div className="col-12 text-right px-0">
                    <IoClose
                      onClick={() => window.$("#exampleModal").modal("hide")}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  </div>
                </div>
                <UserDetailsInfo singleUserDetails={singleUserDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
