/** @format */

import React, { useState, useEffect } from "react";
import group from "../../assets/groups_FILL1_wght300_GRAD0_opsz24.svg";
import self from "../../assets/self_improvement_FILL1_wght300_GRAD0_opsz24.svg";
import { useRegisterAndAttendeesUsers } from "../../context/RegisterAndAttendeesContext";

function Dashboard() {
  const { registerAndAttendee } = useRegisterAndAttendeesUsers();
  const [formValue, setFormValue] = useState({
    value: "All",
  });
  const [categories, setCategories] = useState([]);
  const [totalTMO, setTotalTMO] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    setFormValue({
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (formValue.value === "All") {
      setCategories([...registerAndAttendee]);
    } else {
      let value = registerAndAttendee.filter((cate) => {
        let category = cate && cate.userDetails.category === formValue.value;
        return category;
      });
      setCategories([...value]);
    }
    setTotalTMO([
      ...registerAndAttendee.filter((cate) => {
        let tmo = cate && cate.userDetails.participateInTmo === true;
        return tmo;
      }),
    ]);
  }, [formValue.value, registerAndAttendee]);
  return (
    <div className="row m-0">
      <div className="col-12 mb-2">
        <h4>Dashboard</h4>
      </div>
      <div className="col-12 mb-3">
        <div className="card border-0 shadow">
          <div className="card-body">
            <div className="row m-0 mb-3">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 px-0 mb-2">
                <img src={group} alt="group" className="mr-2 mb-2 mb-md-0" />
                <br className="d-block d-md-none" />
                <span
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "26px",
                    letterSpacing: "0em",
                    textAlign: "left",
                  }}>
                  Total Registrations
                </span>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 mb-2 px-0">
                <div className="form-group">
                  <select
                    id="inputState"
                    className="form-control"
                    onChange={handleChange}>
                    <option value="All">All categories</option>
                    <option value="amorcMember">AMORC Member</option>
                    <option value="amorcMemberLessThan30">
                      Student Member/ LBA AMORC Member (Less than 30 years)
                    </option>
                    <option value="friendsOfAmorc">Friends of AMORC</option>
                    <option value="lbaNonMember">LBA Non-Member</option>
                  </select>
                </div>
              </div>
            </div>
            {categories && <h2> {categories.length} </h2>}
          </div>
        </div>
      </div>
      <div className="col-12 mb-3">
        <div className="card border-0 shadow">
          <div className="card-body">
            <div className="row m-0 mb-3">
              <div className="col-12 px-0">
                <img src={self} alt="self" className="mr-2" />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "19.2px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#605656",
                  }}>
                  Total TMO Participants
                </span>
              </div>
            </div>
            {totalTMO && <h2> {totalTMO.length} </h2>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
