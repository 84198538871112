/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import style from "../style/SignIn.module.css";
import { validateForm } from "../../components/Validation";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

export default function SignIn() {
  const { login } = useUserAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const validationErrors = validateForm(formData);
      if (Object.keys(validationErrors).length === 0) {
        setError("");
        setLoading(true);
        await login(formData.email, formData.password)
          .then((res) => {
            setLoading(false);
            localStorage.setItem("user", JSON.stringify(res.user));
            navigate("/layout/dashboard");
          })
          .catch((err) => {
            setLoading(false);
            const errorCode = err.code;
            const errorMessage = err.message;
            const errorOutput = errorCode.substring(5);
            const editedError = errorOutput.replace(/-/g, " ").toUpperCase();
            setError(editedError);
            console.log("ERROR:", errorCode, errorMessage, editedError);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setErrors(validationErrors);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <h4>Login</h4>
      <form onSubmit={handleSubmit} className="text-left">
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="Username">Username</label>
          <input
            type="email"
            className="form-control"
            id="Username"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && (
            <small className="text-danger">{errors.email}</small>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="Password">Password</label>
          <div className="position-relative">
            <input
              className="form-control"
              type={values.showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <div
              className={style.passwordVisibility}
              onClick={handleClickShowPassword}>
              {values.showPassword ? (
                <AiOutlineEye />
              ) : (
                <AiOutlineEyeInvisible />
              )}
            </div>
          </div>
          {errors.password && (
            <small className="text-danger">{errors.password}</small>
          )}
        </div>
        <button
          type="submit"
          onClick={(e) => handleSubmit(e)}
          className="app-btn-primary">
          Login {loading ? "..." : ""}
        </button>
      </form>
    </div>
  );
}
