/** @format */

import React from "react";
import { Outlet } from "react-router-dom";
import EWAE from "../assets/E-WEST-AFRICAN-A.svg";
import style from "./style/AuthLayout.module.css";

function AuthLayout() {
  return (
    <div className="row m-0 justify-content-center">
      <div
        className={`col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-0 d-none d-lg-block`}>
        <div className={`${style.auth_page}`}>
          <div className="mb-4">
            <img src={EWAE} alt="EWAE" width="200px" />
          </div>
          <h3 className="mb-5" style={{ fontSize: "29px" }}>
            Rosicrucian Convention 2024 <br className="d-none d-md-block" />
            Dashboard
          </h3>
          <h2 style={{ fontSize: "22px" }}>THEME: UNIVERSAL LOVE</h2>
        </div>
      </div>
      <div className={`col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6`}>
        <div
          className={`row m-0 justify-content-center`}
          style={{ height: "100vh", alignItems: "center" }}>
          <div className="col-12 col-md-8 text-center">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
