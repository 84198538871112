/** @format */
import "./App.css";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./route/AuthRoute";
import NotFoundPage from "./components/NotFoundPage";
import AdminRoute from "./route/AdminRoute";

function App() {
  return (
    <Routes>
      <Route path="/*" element={<AuthRoute />} />
      <Route path="/layout/*" element={<AdminRoute />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
