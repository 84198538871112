/** @format */

export const validateForm = (data) => {
  const errors = {};
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Validate email
  if (!data.email.trim()) {
    errors.email = "Email is required";
  } else if (!emailPattern.test(data.email)) {
    errors.email = "Invalid email format";
  }
  // Validate password
  if (!data.password.trim()) {
    errors.password = "Password is required";
  } else if (data.password.length < 6) {
    errors.password = "Password must be at least 6 characters";
  }

  return errors;
};
