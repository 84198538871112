/** @format */

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import style from "./style/DashboardLayout.module.css";
import Header from "./components/Header";
import SideBar from "./components/SideBar";

function DashboardLayout() {
  const [toggle, setToggle] = useState(true);

  return (
    <main className={style.layout}>
      <div className="side-bar bg-white p-0 d-lg-block d-none">
        {toggle ? (
          <div className={style.sidebar}>
            <SideBar />
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="aside p-0 d-lg-none d-block">
        {toggle ? (
          ""
        ) : (
          <div className={style.sidebar}>
            <SideBar setToggle={setToggle} toggle={toggle} />
          </div>
        )}
      </div>

      <div className={`${!toggle ? style.active : style.main_content}`}>
        <div className={style.header}>
          <Header setToggle={setToggle} toggle={toggle} />
        </div>
        <div className={style.main_container}>
          <Outlet />
        </div>
      </div>
    </main>
  );
}

export default DashboardLayout;
