/** @format */

import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

export default function PrivateRoute({ children }) {
  const { currentUser } = useUserAuth();

  return currentUser ? children : <Navigate to="/auth/sign-in" />;
}
