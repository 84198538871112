/** @format */

import React, { useState, useEffect, useCallback } from "react";
import excel from "../../assets/excel_img.svg";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useRegisterAndAttendeesUsers } from "../../context/RegisterAndAttendeesContext";
import { UserDetailsModal } from "../../components/UserDetails";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const headers = [
  { label: "Registration Id", key: "id" },
  { label: "Title", key: "title" },
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Key Number", key: "keyNumber" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phoneNumber" },
  { label: "Category", key: "category" },
  { label: "Country", key: "country" },
  { label: "Jurisdiction", key: "jurisdiction" },
  { label: "Zone", key: "zone" },
  { label: "Affiliated Body", key: "affiliatedBody" },
  { label: "Currency", key: "currency" },
  { label: "Amount Paid", key: "amountPaid" },
  { label: "Registration Fee", key: "registrationFee" },
  { label: "AMRA", key: "amra" },
  { label: "Charges", key: "charges" },
  { label: "Amount Settled", key: "amountSettled" },
  { label: "Serving Officer", key: "servingMember" },
  { label: "Serving Officer Position", key: "servingOfficerPosition" },
  { label: "TMO", key: "tmo" },
  {
    label: "Interested In Discount Accommodation",
    key: "interestedInDiscountAccomodation",
  },
  {
    label: "Interested In Shuttle From Hotel",
    key: "interestedInShuttleFromHotel",
  },
  { label: "Interested In Discount Flight", key: "intrestedInDiscountFlight" },
  {
    label: "Interested In Shuttle From Airport",
    key: "intrestedInShuttleFromAirport",
  },
  { label: "Meal Preference", key: "mealPreference" },
  { label: "Meal Spice Level", key: "mealSpiceLevel" },
  { label: "Additional Attendee", key: "add" },
  { label: "DATE of Registration", key: "date" },
];

function Attendees() {
  const { registerAndAttendee } = useRegisterAndAttendeesUsers();
  const [singleUserDetails, setSingleUserDetails] = useState();
  const [attendeeDataTable, setAttendeeDataTable] = useState([]);
  const [filterAttendeeData, setFilterAttendeeData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleViewSingleUsersInfo = useCallback(
    (UserId) => {
      setSingleUserDetails([
        ...registerAndAttendee.filter((user) => {
          let userInfo = user && user.id === UserId;
          return userInfo;
        }),
      ]);
    },
    [registerAndAttendee]
  );

  const handleSelect = (startDate, endDate) => {
    const newStartDate = moment(startDate, "YYYY-MM-DD");
    const newEndDate = moment(endDate, "YYYY-MM-DD");
    let filtered = filterAttendeeData.filter((attendee) => {
      const attendeeDate = moment(attendee.date, "MMMM Do YYYY");
      const isAfterStartDate = attendeeDate.isSameOrAfter(newStartDate);
      const isBeforeEndDate = attendeeDate.isSameOrBefore(newEndDate);

      return isAfterStartDate && isBeforeEndDate;
    });
    setStartDate(startDate);
    setEndDate(endDate);

    setAttendeeDataTable([...filtered]);
  };

  const getCategories = (category) => {
    if (category === "amorcMember") {
      return "AMORC Member";
    } else if (category === "amorcMemberLessThan30") {
      return "Student Member/ LBA AMORC Member (Less than 30 years)";
    } else if (category === "friendsOfAmorc") {
      return "Friends of AMORC";
    } else if (category === "lbaNonMember") {
      return "LBA Non-Member";
    } else {
      return "N/A";
    }
  };

  const handleRegisterAndAttendeeDataTable = useCallback(() => {
    let newRegisterAndAttendee = registerAndAttendee.map((userInfo) => ({
      id: userInfo.id,
      firstName: userInfo.userDetails.firstName,
      lastName: userInfo.userDetails.lastName,
      keyNo:
        userInfo.userDetails.keyNumber === undefined
          ? "N/A"
          : userInfo.userDetails.keyNumber,
      category: getCategories(userInfo.userDetails.category),
      tmo: userInfo.userDetails.participateInTmo === true ? "Yes" : "No",
      country: userInfo.userDetails.country,
      servingMember: userInfo.userDetails.servingMember === true ? "Yes" : "No",
      jurisdiction: userInfo.userDetails.jurisdiction,
      title: userInfo.userDetails.title,
      keyNumber: userInfo.userDetails.keyNumber,
      phoneNumber: userInfo.userDetails.phoneNumber,
      zone: userInfo.userDetails.zone?userInfo.userDetails.zone:"diaspora",
      affiliatedBody: userInfo.userDetails.affiliatedBody,
      participateInTmo:
        userInfo.userDetails.participateInTmo === true ? "Yes" : "No",
      amountPaid: userInfo.amountPaid,
      registrationFee: userInfo.registrationFee,
      charges: userInfo.charges,
      currency: userInfo.currency,
      amountSettled: userInfo.amountSettled,
      interestedInDiscountAccomodation:
        userInfo.preference.interestedInDiscountAccomodation === true
          ? "Yes"
          : "No",
      interestedInShuttleFromHotel:
        userInfo.preference.interestedInShuttleFromHotel === true
          ? "Yes"
          : "No",
      intrestedInDiscountFlight:
        userInfo.preference.intrestedInDiscountFlight === true ? "Yes" : "No",
      intrestedInShuttleFromAirport:
        userInfo.preference.intrestedInShuttleFromAirport === true
          ? "Yes"
          : "No",
      mealPreference: userInfo.preference.mealPreference,
      mealSpiceLevel: userInfo.preference.mealSpiceLevel,
      email: userInfo.userDetails.email,
      amra: userInfo.userDetails.amra,
      servingOfficerPosition: userInfo.userDetails.servingOfficerPosition,
      add:
        userInfo.additionalAttendeeInfo &&
        userInfo.additionalAttendeeInfo.attendingWithSomeone === true
          ? userInfo.additionalAttendeeInfo.additionalAttendees.length
          : 0,
      date: moment(userInfo.createdAt.toDate()).format("MMMM Do YYYY"),

      action: (
        <button
          onClick={() => handleViewSingleUsersInfo(userInfo.id)}
          type="button">
          ...
        </button>
      ),
    }));
    setAttendeeDataTable([...newRegisterAndAttendee]);
    setFilterAttendeeData([...newRegisterAndAttendee]);
  }, [registerAndAttendee, handleViewSingleUsersInfo]);

  useEffect(() => {
    handleRegisterAndAttendeeDataTable();
  }, [handleRegisterAndAttendeeDataTable]);

  return (
    <>
      <UserDetailsModal singleUserDetails={singleUserDetails} />
      <div className="row m-0">
        <div className="col-12 col-md-6 mb-2">
          <h4>Registered Attendees</h4>
        </div>
        <div className="col-12 col-md-6 text-md-right mb-3">
          <div style={{ cursor: "pointer" }}>
            <CSVLink
              data={attendeeDataTable}
              headers={headers}
              filename={"AttendeeData.csv"}>
              <img src={excel} alt="excel" className="mr-1" />
              Export data sheet to Excel
            </CSVLink>
          </div>
        </div>
        <div className="col-12 text-left">
          <ul className="nav align-items-center">
            <li className="nav-item mr-2">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example">
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setStartDate(e.target.value)}
                  min="2023-11-01"
                />
                <button type="button" className="btn btn-transparent">
                  TO
                </button>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => setEndDate(e.target.value)}
                  min="2023-11-01"
                />
              </div>
            </li>
            <li className="nav-item">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example">
                <button
                  onClick={() => handleSelect(startDate, endDate)}
                  type="button"
                  style={{
                    borderRadius: "0px",
                    width: "100px",
                  }}
                  className="app-btn-primary rounded mr-1">
                  Apply
                </button>
                <button
                  onClick={() => setAttendeeDataTable([...filterAttendeeData])}
                  type="button"
                  style={{
                    borderRadius: "0px",
                    width: "100px",
                  }}
                  className="app-btn-primary rounded">
                  Reset
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 mt-3 bg-white rounded py-3">
          <DataTable
            value={attendeeDataTable}
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}>
            <Column field="id" header="ID"></Column>
            <Column field="firstName" header="FirstName"></Column>
            <Column field="lastName" header="LastName"></Column>
            <Column field="keyNo" header="KeyNo" sortable></Column>
            <Column field="category" header="Category" sortable></Column>
            <Column field="tmo" header="TMO"></Column>
            <Column field="country" header="Country"></Column>
            <Column field="add" header="Additional Attendee"></Column>
            <Column field="date" header="Date" sortable></Column>
            <Column field="action" header="Action"></Column>
          </DataTable>
        </div>
      </div>
    </>
  );
}

export default Attendees;
