/** @format */

import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

export default function PublicRoute({ children }) {
  const { currentUser } = useUserAuth();
  return currentUser ? <Navigate to="/layout/dashboard" /> : children;
}
