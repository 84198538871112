/** @format */

import React, { useEffect, useState } from "react";
import style from "../assets/UserDetails.module.css";
import pen from "../assets/edit_FILL1_wght300_GRAD0_opsz24.svg";
import cash from "../assets/payments_FILL1_wght300_GRAD0_opsz24.svg";
import moment from "moment";
import CurrencyFormat from "react-currency-format";

function UserDetailsInfo({ singleUserDetails }) {
  const [userDetails, setUserDetails] = useState({});

  const getCategories = (category) => {
    if (category === "amorcMember") {
      return "AMORC Member";
    } else if (category === "amorcMemberLessThan30") {
      return "Student Member/ LBA AMORC Member (Less than 30 years)";
    } else if (category === "friendsOfAmorc") {
      return "Friends of AMORC";
    } else if (category === "lbaNonMember") {
      return "LBA Non-Member";
    } else {
      return "N/A";
    }
  };

  useEffect(() => {
    if (singleUserDetails && Object.keys(singleUserDetails).length > 0) {
      setUserDetails(
        {
          ...singleUserDetails.map((userInfo) => {
            return userInfo;
          }),
        }[0]
      );
      window.$("#exampleModal").modal("show");
    }
  }, [singleUserDetails, userDetails]);
  return (
    <>
      {userDetails.userDetails !== undefined && (
          <>
            <div className="row m-0 mb-3">
              <div className="col-6">
                <p className={`${style.verify_paragraph}`}>
                  <span className={`${style.paragraph_label} mr-2`}>ID:</span>#
                  {userDetails.id}
                  <img src={pen} alt="pen" className="ml-1"/>
                </p>
              </div>
                <div className="col-6">
                  <p className={`${style.verify_paragraph}`}>
                    <span className={`${style.paragraph_label} mr-2`}>Key Number:</span>
                    {userDetails.userDetails.keyNumber}
                  </p>
                </div>
            </div>
            <div className="row m-0 mb-5">
              <div className="col-12 col-sm-6 col-lg-4">
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                  <span className={`${style.paragraph_label} mr-2`}>Title:</span>
                  {userDetails.userDetails.title}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  First Name:
                </span>
                  {userDetails.userDetails.firstName}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Last Name:
                </span>
                  {userDetails.userDetails.lastName}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Category:
                </span>
                  {getCategories(userDetails.userDetails.category)}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Jurisdiction:
                </span>
                  {userDetails.userDetails.jurisdiction}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Country:
                </span>
                  {userDetails.userDetails.country}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Phone No:
                </span>
                  {userDetails.userDetails.phoneNumber}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                  <span className={`${style.paragraph_label} mr-2`}>Email:</span>
                  {userDetails.userDetails.email}
                </p>
                <p className={`${style.verify_paragraph} ${style.userInfo} mb-2`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  TMO Participation?:
                </span>
                  {userDetails.userDetails.participateInTmo === true
                      ? "Yes"
                      : "No"}
                </p>
              </div>
              {userDetails.additionalAttendeeInfo &&
              userDetails.additionalAttendeeInfo.attendingWithSomeone === true ? (
                  <div className="col-12 col-sm-6 col-lg-4">
                    <div className={`${style.verify_paragraph}`}>
                  <span className={`${style.paragraph_label} mr-2`}>
                    Additional Attendees:
                    {
                      userDetails.additionalAttendeeInfo.additionalAttendees
                          .length
                    }
                  </span>
                      {userDetails.additionalAttendeeInfo.additionalAttendees.map(
                          (invite, i) => (
                              <ol key={i} className={`${style.list_style} mt-2`}>
                                <li className="mb-2">
                                  {invite.name}
                                  <br/>
                                  <small className={`${style.paragraph_label}`}>
                                    {invite.category}
                                  </small>
                                </li>
                              </ol>
                          )
                      )}
                    </div>
                  </div>
              ) : (
                  ""
              )}
              <div className="col-12 col-sm-6 col-lg-4">
                <div className={`${style.verify_paragraph}`}>
                <span className={`${style.paragraph_label} mr-2`}>
                  Meal Preferences:
                </span>
                  <ul className={`${style.list_style} mt-2`}>
                    <li>{userDetails.preference.mealPreference} </li>
                    <li>{userDetails.preference.mealSpiceLevel}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-12 col-md-6">
                <p className={`${style.verify_paragraph}`}>
                  <img src={cash} alt="cash" className="mr-1"/>
                  <span className={`${style.paragraph_label} mr-2`}>
                  Registration fee:
                </span>
                  <CurrencyFormat
                      value={userDetails.amountPaid}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={userDetails.currency}
                  />
                </p>
              </div>
              <div className="col-12 col-md-6 text-md-right">
              <span className={`${style.paragraph_label}`}>
                {moment(userDetails.createdAt.toDate()).format("MMMM Do YYYY")}
              </span>
              </div>
            </div>
          </>
      )}
    </>
  );
}

export default UserDetailsInfo;
