/** @format */

import React, { useEffect, useState } from "react";
import { SlMenu } from "react-icons/sl";

function Header({ setToggle, toggle }) {
  const [userEmail, setUserEmail] = useState();
  const updateToggle = () => {
    if (toggle) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("user");
    setUserEmail(user);
  }, [setUserEmail]);

  return (
    <nav
      className="row m-0 align-items-center justify-content-between"
      style={{ height: "10vh" }}>
      <div className="col">
        <SlMenu
          onClick={() => updateToggle()}
          style={{ fontSize: "20px", cursor: "pointer" }}
        />
      </div>
      <ul className={`nav align-items-center justify-content-end px-4`}>
        {userEmail && (
          <li className="nav-item font-weight-bold">
            {JSON.parse(userEmail).email}
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Header;
