/** @format */

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { db } from "../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

const registerAndAttendeesContext = createContext();

export function RegisterAndAttendeesContextProvider({ children }) {
  const [registerAndAttendee, setRegisterAndAttendee] = useState();
  const [loading, setLoading] = useState(true);

  const handleRegistration = useCallback(async () => {
    let userInfo = [];
    const querySnapshot = await getDocs(
        query(collection(db, `${process.env.REACT_APP_DB_PREFIX}_registration`), orderBy('createdAt', 'desc'))
    );
    querySnapshot.forEach((doc) => {
      setLoading(false);
      let documentData = doc.data();
      documentData.id = doc.id;
      userInfo.push(documentData);
      setRegisterAndAttendee([...userInfo]);
    });
  }, [setLoading, setRegisterAndAttendee]);

  useEffect(() => {
    handleRegistration();
  }, [handleRegistration]);

  return (
    <registerAndAttendeesContext.Provider value={{ registerAndAttendee }}>
      {!loading && children}
    </registerAndAttendeesContext.Provider>
  );
}

export function useRegisterAndAttendeesUsers() {
  return useContext(registerAndAttendeesContext);
}
