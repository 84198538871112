/** @format */

import React, { memo } from "react";

const NotFoundPage = memo(() => {
  return (
    <div>
      <p>This is a not found Page</p>
    </div>
  );
});

export default NotFoundPage;
